$ = window.jQuery = require './lib/jquery.min'
_ = require './lib/underscore-min'
require './lib/jquery.easing.min'
require './lib/velocity.min'
preloadjs = require 'preloadjs'

_ua = navigator.userAgent
_sp = _ua.indexOf('iPhone') > -1 || _ua.indexOf('iPod') > -1 || _ua.indexOf('Android') > -1
_device = if _sp then 'sp' else 'pc'
_ww = $(window).width()
_wh = $(window).height()
_bw = if !_sp then 960 else _ww
_deg = 12.5
_rad = 0.2216946626429399


class ReplaceImg

  constructor : (@$target, @func) ->
    _this = @
    if !_sp then @device = "pc" else @device = "sp"

    @$target.each (i) ->
      if $(@).data("src")?
        if !$(@).data("device")? || $(@).data("device") == _this.device
          $(@).attr("src", $(@).data("src").replace("device", _this.device))

      if i == _this.$target.length - 1
        if _this.func?
          _this.func()

class ScrollLink

  constructor : ($target) ->
    top = Math.floor $($target.attr('href')).offset().top

    $target.on 'click', =>
      $('html,body').animate
        scrollTop:top
      ,1000 ,'easeInOutCubic'

      return false

class Oblique

  constructor : ->


  resize : ->
    $('.m-mask-top-up, .m-mask-top-down').each ->
      $(@).prepend('<div class="mask-top"></div>')

      $(@).css
        'margin-top': Math.round(-_rad * _ww * 0.5 + _rad * _bw * 0.5)
        'padding-top': Math.round(_rad * _ww * 0.5 + _rad * _bw * 0.5)
      $(@).children('.mask-top').css
        'height': Math.round(_rad * _ww)
        'top': Math.round(-_rad * _ww * 0.5)

    $('.m-mask-bottom-up, .m-mask-bottom-down').each ->
      $(@).prepend('<div class="mask-bottom"></div>')

      $(@).css
        'margin-bottom': Math.round(-_rad * _ww * 0.5 + _rad * _bw * 0.5)
        'padding-bottom': Math.round(_rad * _ww * 0.5 + _rad * _bw * 0.5)
      $(@).children('.mask-bottom').css
        'height': Math.round(_rad * _ww)
        'bottom': Math.round(-_rad * _ww * 0.5)

    $('.m-blocks').each ->
      $(@).children('.bg').css
        'height':$(@).height() - parseInt($(@).children('.bg').css('top'))*2 - Math.round(_rad * _bw * 2)
        #'height':$(@).height() - parseInt($(@).children('.bg').css('top'))*2 - Math.round(_rad * _ww + _rad * _bw)

class Header

  constructor : ->
    @$lHeader = $('.l-header')
    @$nav = @$lHeader.find '.nav'
    @$navBtn = @$nav.find '.btn'
    @$navInner = @$nav.children '.inner'
    @$navClose = @$navInner.find '.close'
    @$navList = @$navInner.find '.list'
    @$navContact = @$navInner.find '.contact'

    @setNav() if _sp

  setNav : ->
    @$navBtn.on 'touchstart', =>
      @open()

    @$navClose.on 'touchstart', =>
      @close()

  open : ->
    @$navInner.css
      'right':'-75%'
      'display':'block'
    .velocity
      'right':'0%'
    ,500 ,'easeInOutExpo'

    @$navList.find('li').each (index) ->
      $(@).css
        'right':'-90%'
      .velocity
        'right':'0%'
      ,
        duration:500
        easing:'easeOutCubic'
        delay:50*index + 125

    @$navContact.css
      'right':'-90%'
    .velocity
      'right':'0%'
    ,
      duration:500
      easing:'easeOutCubic'
      delay:50*5 + 125

  close : ->
    @$navInner.velocity
      'right':'-75%'
    ,500 ,'easeInOutQuart' ,=>
      @$navInner.css
        'display':'none'

class Contact

  constructor : ->
    @$lContact = $('.l-contact')
    @$contactInner = @$lContact.children '.inner'
    @$contactInnerInner = @$contactInner.children '.inner'

  resize : ->
    @$lContact.css
      'margin-top': Math.round(-_rad * _ww * 0.5 + _rad * _bw * 0.5)
    @$contactInner.css
      'top': Math.round(_rad * _ww * 0.5)
      'padding-top': Math.round(_rad * _ww * 0.5 + _rad * _bw * 0.5)
    @$contactInnerInner.css
      'top': Math.round(-_rad * _ww * 0.5)

class Footer

  constructor : ->
    @$lFooter = $('.l-footer')
    @$pagetop = @$lFooter.find '.pagetop'

    @pagetop()

  pagetop : ->
    @$pagetop.on 'click', =>
      $('html,body').animate
        scrollTop:0
      ,1000 ,'easeOutCubic'


class Load

  constructor : (@manifest, @func) ->
    @$body = $('body')
    @$lLoad = $('.l-load')
    @$num = @$lLoad.find '.num'
    @$numH = @$num.find '.h'
    @$numT = @$num.find '.t'
    @$numO = @$num.find '.o'
    @$kanji = @$lLoad.find '.kanji'
    @$mask = @$lLoad.find '.mask'
    @$maskTop = @$mask.find '.top'
    @$maskBottom = @$mask.find '.bottom'

    @nowProg = 0
    @displayProg = 0
    @kanjiW = @$kanji.width()
    @isComp = false
    @timerId = ''

    @init()
    @load()

  init : ->
    @$body.css 'overflow':'hidden'
    $(window).on 'touchmove.noScroll', (e) ->
      e.preventDefault()

    @$num.css 'opacity':0
    @$kanji.css 'opacity':0

    @$mask.css
      'width':'0%'

    @$maskTop.css
      'width':_ww
      'height':_wh*0.5 + Math.round(_rad * _ww * 0.5)
      'top':Math.round(-_rad * _ww * 0.5)

    @$maskBottom.css
      'width':_ww
      'height':_wh*0.5 + Math.round(_rad * _ww * 0.5)
      'bottom':Math.round(-_rad * _ww * 0.5)

  load : ->
    loadQueue = new createjs.LoadQueue()
    loadQueue.setMaxConnections(6)

    loadQueue.addEventListener 'progress', (event) =>
      @nowProg = Math.round(event.progress * 100)
 


    @animateLoadStart =>
      loadQueue.loadManifest @manifest

      @timerId = setInterval =>
        @animateLoad()
      ,10

  animateLoad : (func) ->
    if @displayProg <= 100
      if @displayProg <= @nowProg
        if @displayProg < 10
          num = '00' + @displayProg
        else if @displayProg >= 10 && @displayProg < 100
          num = '0' + @displayProg
        else
          num = @displayProg

        @displayProg++

        @$numH.text String(num).substr(0, 1)
        @$numT.text String(num).substr(1, 1)
        @$numO.text String(num).substr(2, 1)

        if @displayProg >= 10 && @displayProg <= 100
          if String(@displayProg).substr(0, 1) != String(num).substr(1, 1)
            kanji = if @displayProg < 100 then String(@displayProg).substr(0, 1) else 10
            @animateLoadKanji kanji
    else
      clearInterval @timerId

      setTimeout =>
        @animateLoadEnd()
      ,250

      setTimeout =>
        @enable()
      ,250

  animateLoadStart : (func) ->
    setTimeout =>
      @animateLoadKanji 0

      @$num.velocity
        'opacity':1
      ,
        duration:250
        easing:'easeOutCubic'
        mobileHA:false
        complete: func
    ,250

  animateLoadEnd : ->
    @$num.velocity
      'opacity':0
    ,
      duration:500
      easing:'easeOutCubic'
      mobileHA:false

    @$kanji.velocity
      'opacity':0
    ,500, 'easeOutCubic'

  animateLoadKanji : (num) ->
    @$kanji.css
      'background-position':"-#{num*@kanjiW}px 0"
      'display':'block' 
      'opacity':0
    .velocity('stop').velocity
      'scale':1.375
    ,1, =>
      @$kanji.velocity
        'scale':1
        'opacity':1
      ,500 ,'easeOutExpo'

  enable : ->
    @$mask.velocity
      'width':'100%'
    ,375, 'easeInExpo' ,=>
      @$lLoad.css
        'background':'transparent'
      @$mask.css
        'background':'transparent'

    setTimeout =>
      @$maskTop.velocity
        'top': -@$maskTop.height() + Math.round(-_rad * _ww * 0.5)
      ,
        duration:750
        easing:'easeInOutExpo'
        mobileHA:false

      @$maskBottom.velocity
        'bottom': -@$maskBottom.height() + Math.round(-_rad * _ww * 0.5)
      ,
        duration:750
        easing:'easeInOutExpo'
        mobileHA:false
    ,375

    setTimeout =>
      @$lLoad.remove()
      @$body.css 'overflow':'visible'
      $(window).off '.noScroll'
      @isComp = true
      @func()
    ,1125

  resize : ->
    if !@isComp
      @$maskTop.css
        'width':_ww
        'height':_wh*0.5 + Math.round(_rad * _ww * 0.5)
        'top':Math.round(-_rad * _ww * 0.5)

      @$maskBottom.css
        'width':_ww
        'height':_wh*0.5 + Math.round(_rad * _ww * 0.5)
        'bottom':Math.round(-_rad * _ww * 0.5)

class Index

  constructor : ->
    @$lVisual = $('.l-visual')
    @$visual = @$lVisual.find '.visual'
    @$visualTitle = @$visual.find '.title'
    @$visualTitleCharactor = @$visualTitle.find '.charactor'
    @$visualCopy = @$visual.find '.copy'
    @$visualCopyLine = @$visualCopy.find '.line'
    @$visualImg = @$visual.find '.img'
    @$scroll = @$lVisual.find '.scroll'
    @$line = $('.l-about .line, .l-service .line, .l-member .line, .l-case .line')
    @$lineInner = @$line.children '.inner'
    @$lineInnerImg = @$lineInner.find '.img'
    @$kanji = $('.l-about .kanji, .l-service .kanji, .l-member .kanji, .l-case .kanji')

    @bodyWidth = if !_sp then 960 else 550
    @lineHeight = if !_sp then 200 else 100
    @flg =
      'about':false
      'service':false
      'member':false
      'case':false

    @init()

  init : ->
    @$visualTitleCharactor.velocity
      'opacity':0
      'scale':1.125
    ,1

    if !_sp
      @$visualCopyLine.css 'width':0
    else
      @$visualCopyLine.css
        'height':0
        'margin-top':12

    @$visualImg.css 'opacity':0
    @$scroll.css 'opacity':0

    #####

    @$line.css 'width': '0%'
    @$lineInner.css 'height': 1

    r = if !_sp then 2 else 8
    @$lineInnerImg.css 'top': Math.round(-_rad * @bodyWidth * 0.5) - Math.round(_rad * @bodyWidth * 0.5) + r

    @$kanji.velocity
      'opacity': 0
      'scale': 1.375
    ,0

  animate : ->
    @animateImg()

    setTimeout =>
      @animateTitle()
    ,1500

    setTimeout =>
      @animateCopy()
    ,2500

    setTimeout =>
      @animateScroll()
    ,3500

  animateImg : ->
    i = 0
    w = if !_sp then 950 else 421

    setInterval =>
      if i < 11 then i++ else i = 0
      @$visualImg.css
        'background-position': "#{-w*i}px 0"
    ,100

    @$visualImg.velocity
      'opacity':1
    ,2000 ,'linear'

  animateCopy : ->
    @$visualCopyLine.each (index) ->
      if !_sp
        $(@).velocity
          'width':20
        ,
          duration:750
          easing:'easeInOutQuint'
          delay:125*index
      else
        $(@).velocity
          'height':12
          'margin-top':0
        ,
          duration:750
          easing:'easeInOutQuint'
          delay:125*index

  animateTitle : ->
    w = if !_sp then 126 else 38

    rand = [4,2,1,5,0,6,3]
    @$visualTitleCharactor.each (index) ->
      $(@).velocity
        'opacity':1
        'scale':1
      ,
        duration:1000
        easing:'easeOutCubic'
        delay:125*rand[index]

  animateScroll : ->
    @$scroll.velocity
      'opacity':1
    ,500 ,'linear'

  resize : ->
    @$lVisual.css
      'height': _wh
      'margin-bottom': Math.round(_rad * _ww * 0.5)

    @$line.css
      'top': Math.round(-_rad * _ww * 0.5)
      'height': @lineHeight + Math.round(_rad * _ww)

    @$lineInner.css
      'width': _ww

  scroll : (s) ->
    @scrollAnimate s, $('.l-about'), 'about'
    @scrollAnimate s, $('.l-service'), 'service'
    @scrollAnimate s, $('.l-member'), 'member'
    @scrollAnimate s, $('.l-case'), 'case'

  scrollAnimate : (s, $obj, flg) ->
    if !@flg[flg]
      if s > $obj.offset().top - $(window).height()/2 - Math.round(_rad * $(window).width() * 0.5)
        $obj.find('.line').velocity
          'width': '100%'
        ,375, 'easeInExpo'

        setTimeout =>
          $obj.find('.line .inner').velocity
            'height': @lineHeight
          ,
            duration:375
            easing:'easeInOutExpo'
            mobileHA:false

          $obj.find('.line .inner .img').velocity
            'top': Math.round(-_rad * @bodyWidth * 0.5)
          ,
            duration:375
            easing:'easeInOutExpo'
            mobileHA:false
        ,375

        setTimeout =>
          $obj.find('.kanji').velocity
            'opacity':1
            'scale':1
          ,500 ,'easeOutExpo'
        ,625

        @flg[flg] = true

class Company

  constructor : ->
    @$lPhoto = $('.l-photo')
    @$photoImg = @$lPhoto.find '.img'

    @photoLength = @$photoImg.length - 1
    @nowPhoto = 0
    @prevPhoto = @photoLength
    @photoMinWidth = parseInt(@$photoImg.css('min-width'))
    
    @init()
    @slidePhoto()
    initialize()

  init : ->
    @$photoImg.css 'display':'none'
    @$photoImg.eq(0).css 'display':'block'

  slidePhoto : ->
    @prevPhoto = @nowPhoto
    @nowPhoto = if @nowPhoto < @photoLength then @nowPhoto + 1 else 0

    @$photoImg.eq(@prevPhoto).css
      'z-index':0

    @$photoImg.eq(@nowPhoto).css
      'display':'block'
      'z-index':1
      'opacity':0
    .velocity
      'opacity':1
    ,
      duration:2000
      easing:'linear'
      mobileHA:false
      complete:=>
        @$photoImg.eq(@prevPhoto).css
          'opacity':0

        setTimeout =>
          @slidePhoto()
        ,1000


  resize : ->
    if _ww >= @photoMinWidth
      @$photoImg.css
        'top': Math.round(-_rad * _ww * 0.5)
    else
      @$photoImg.css
        'top': Math.round(-_rad * @photoMinWidth * 0.5)


class Service

  constructor : ->
    @$lNav = $('.l-nav')
    @$navList = @$lNav.find '.list'
    @$navListA = @$navList.find 'a'

    @$navListA.each ->
      new ScrollLink $(@)


$ ->
  $body = $('body')

  new ReplaceImg $('img')

  new Header()
  new Footer()
  oblique = new Oblique
  contact = new Contact

  if $body.hasClass 'index'
    manifest =　[
      "./assets/images/#{_device}/index/img_visual.png"
      "./assets/images/#{_device}/index/h_visual_b.png"
      "./assets/images/#{_device}/index/h_visual_e.png"
      "./assets/images/#{_device}/index/h_visual_p.png"
      "./assets/images/#{_device}/index/h_visual_r.png"
      "./assets/images/#{_device}/index/h_visual_o.png"
      "./assets/images/#{_device}/index/h_visual_u.png"
      "./assets/images/#{_device}/index/h_visual_d.png"
      "./assets/images/#{_device}/index/txt_visual_1.png"
      "./assets/images/#{_device}/index/txt_visual_2.png"
      "./assets/images/#{_device}/index/txt_visual_3.png"
    ]

    index = new Index
    load = new Load manifest, =>
      index.animate()

  company = new Company if $body.hasClass 'company'
  service = new Service if $body.hasClass 'service'

  


  $(window).on 'resize', =>
    _ww = $(window).width()
    _wh = $(window).height()
    _bw = if !_sp then 960 else _ww
    
    oblique.resize()
    contact.resize()

    if $body.hasClass 'index'
      index.resize()
      load.resize()

    company.resize() if $body.hasClass 'company'

  $(window).resize()



  $(window).on 'scroll', =>
    s = $(window).scrollTop()

    index.scroll s if $body.hasClass 'index'

  setTimeout =>
    $('html,body').scrollTop 0
  ,100
